import type { ReactNode } from 'react';
import { cn } from '~/lib/utils';

export function Label({ id, children, className }: { id: string; children: ReactNode; className?: string }) {
  return (
    <label htmlFor={id} className={cn('block w-full mb-2 text-sm font-medium', className)}>
      {children}
    </label>
  );
}
